<template>  
  <div class="home-container">  
    <div class="linear-bg"></div>  
    <div class="home-scroll">  
      <!-- 轮播图片 -->  
      <div class="banner">  
        <swiper class="banner_swiper" :options="bannerSwiperOption">  
          <swiper-slide v-for="(banner, index) in banners" :key="index">  
            <van-image class="banner_img" round :src="banner.url" />  
          </swiper-slide>  
        </swiper>  
      </div>  
      <!-- 公告栏 -->  
      <div class="notice-bar">  
        <van-notice-bar  
          class="notice-swipe"  
          left-icon="bullhorn-o"  
          background="#ffffff"  
          color="#7e5678"  
          :text="notice"  
        />  
        <div class="linear-gradient"></div>  
        <!-- 用户信息展示区域 -->  
        <div class="user-info">  
            <img class="user-img" :src="'/img/sy/1.png'" alt="用户头像">
          <div class="user-details">  
            <p>姓名：{{ userInfo.name }}</p>  
            <p>年龄：{{ userInfo.age }}</p>  
            <p>性格：{{ userInfo.personality }}</p>  
            <p>地区：{{ userInfo.region }}</p>  
          </div>  
        </div>  
      </div>  
    </div>  
  </div>  
</template>  
  
<script>  
export default {  
  data() {  
    return {  
      notice: "取得中......",  
      banners: [],  
      userInfo: {  
        imgUrl: '',  
        name: '',  
        age: '',  
        personality: '',  
        region: ''  
      },  
      bannerSwiperOption: {  
        effect: 'coverflow',  
        grabCursor: true,  
        centeredSlides: true,  
        slidesPerView: 'auto',  
        speed: 800,  
        autoplay: true,  
        coverflowEffect: {  
          rotate: 50,  
          stretch: 10,  
          depth: 100,  
          modifier: 1,  
          slideShadows: true  
        }  
      }  
    };  
  },  
  methods: {  
    getBasicConfig() {  
      this.$http({  
        method: 'get',  
        url: 'sys_config'  
      }).then(res => {  
        this.updateConfig(res.data);  
      }).catch(error => {  
        console.error('Failed to fetch config:', error);  
      });  
    },  
    updateConfig(data) {  
      this.notice = data.notice || "取得中......";  
      this.banners = data.banners || [];  
      this.userInfo = data.userInfo || {  
        imgUrl: '',  
        name: '',  
        age: '',  
        personality: '',  
        region: ''  
      };  
    }  
  },  
  created() {  
    this.getBasicConfig();  
  }  
};  
</script>  
  
<style lang='less' scoped>  
@notice-bar-size: 30px;  
  
.linear-bg {  
  height: 200px;  
  background: linear-gradient(270deg, #e6c3a1, #7e5678);  
}  
  
.home-container {  
  position: absolute !important;  
  top: 0;  
  left: 0;  
  right: 0;  
  background-color: #ffffff;  
}  
  
.linear-gradient {  
  width: 100%;  
  height: 2px;  
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));  
}  
  
::v-deep .van-notice-bar__left-icon,  
.van-notice-bar__right-icon {  
  min-width: 40px;  
}  
  
.notice-swipe {  
  width: calc(100% - 50px);  
  height: 85px;  
  font-size: @notice-bar-size;  
}  
  
::v-deep .van-icon-bullhorn-o::before {  
  transform: scale(2.5);  
}  
  
.banner {  
  width: 100%;  
  margin-top: -23%;  
}  
  
.banner_swiper {  
  height: 100%;  
  width: 100%;  
  
  .swiper-slide {  
    border-radius: 10px;  
    display: flex;  
    justify-content: center;  
    align-items: center;  
    width: 620px;  
    height: 300px;  
    text-align: center;  
    font-weight: bold;  
    font-size: 20px;  
    background-color: #ffffff;  
    background-position: center;  
    background-size: cover;  
    color: #ffffff;  
  }  
}  
  
::v-deep .swiper-container-3d .swiper-slide-shadow-left {  
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));  
}  
  
::v-deep .swiper-container-3d .swiper-slide-shadow-right {  
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));  
}  
  
.banner_img {  
  border-radius: 10px;  
  width: 100%;  
  height: 100%;  
}  
</style>  
  
<style scoped>  
.user-info {  
  display: flex;  
  align-items: center;  
  margin-top: 20px;  
}  
  
.user-img {  
  width: 100px;  
  height: 100px;  
  border-radius: 50%;  
  margin-right: 20px;  
}  
  
.user-details p {  
  margin: 0;  
  line-height: 1.5;  
}  
</style>